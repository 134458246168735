// https://testing-core.deploy.kuarasoftware.es/

export const API = {
	products: {
    create: `/product`,
    edit: `/product/`,
    delete: `/product/`,
    list: `/product`,
  },
	laundrys: {
    create: `/laundry`,
    edit: `/laundry/`,
    delete: `/laundry/`,
    list: `/laundry`,
  },
	customers: {
    create: `/customer`,
    edit: `/customer/`,
    delete: `/customer/`,
    list: `/customer`,
  },
	machines: {
    create: `/machine`,
    edit: `/machine/`,
    delete: `/machine/`,
    list: `/machine`,
  },
  auth: {
    login: `/user/login`,
    check2FA: `/user/check2FA`,
  },
  users: {
    profile: `/user/profile`,
    create: `/user/register/`,
    edit: `/user/`,
    delete: `/user/`,
    list: `/user`,
  },
  alerts: {
    create: `/alert`,
    edit: `/alert/`,
    delete: `/alert/`,
    list: `/alert`,
  },
};
