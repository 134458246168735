import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_PRODUCT,
    DELETE_PRODUCT,
    PRODUCT_FORM_TOOGLE_LOADING,
    SET_PRODUCT_LIST,
    SHOW_NOTIFICATION,
    UPDATE_PRODUCT,
} from "../constants";
import { formatProducts } from "./settingsActionsUtils";

/* PRODUCT LIST */
export const fetchProducts = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.products.list)
            .then((response) => {
                const products = formatProducts(response.data);
                dispatch({
                    type: SET_PRODUCT_LIST,
                    payload: keyBy(products, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// PRODUCTS
export const createProduct = (newProduct) => {
    return async (dispatch) => {
        dispatch({ type: PRODUCT_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.products.create, newProduct)
            .then((response) => {
                const product = formatProducts(response.data);
                dispatch({ type: ADD_NEW_PRODUCT, payload: product });
                dispatch({ type: PRODUCT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Producto creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PRODUCT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateProduct = (updatedProduct) => {
    return async (dispatch) => {
        dispatch({ type: PRODUCT_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.products.edit}${updatedProduct && updatedProduct._id}`, updatedProduct)
            .then((response) => {
                const product = formatProducts(response.data);
                dispatch({ type: UPDATE_PRODUCT, payload: product });
                dispatch({ type: PRODUCT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Producto actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PRODUCT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteProducts = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: PRODUCT_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.products.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_PRODUCT, payload: Ids });
                dispatch({ type: PRODUCT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Producto eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PRODUCT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
